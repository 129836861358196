import React from "react"
import { graphql } from "gatsby"
import Navbar from "../../components/NavBar"
import Carousel from "../../components/Carousel"
import Footer from "../../components/Footer"
import Metatags from "../../components/metatags"
import UseSiteMetadata from "../../components/UseSiteMetadata"

const daucodesignsEN = ({ pageContext, data, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const  fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo:"https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "es-ES",

      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | daucodesigns"
        description="website para daucodesigns"
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <Navbar />
      <div className="h-10" />
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <h1>daucodesigns</h1>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-6">
            <button
              className="btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 px-3 py-1.5"
              href="https://daucodesigns.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              daucodesigns.com
            </button>
            <div className="h-5" />
            <p>
              Daucodesigns es una empresa de muebles home & contract con
              oficinas en Madrid y Barcelona.
            </p>
            <p>
              El principal objetivo del website es atraer clientes del segmento
              a través de una gran cantidad de imágenes de muebles de las
              principales marcas comercializadas.
            </p>
            <p>
              A petición del cliente, desarrollamos un carrusel personalizado
              con imágenes a pantalla completa con piezas individuales y también
              entornos en que son utilizadas.
            </p>
          </div>
        </div>
        <div className="h-8" />
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-2">desarrollo</div>
          <div className="col-span-4 tec-feature">gatsby js</div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-2">integraciones</div>
          <div className="col-span-4 tec-feature">
            architonic - plataforma de arquitectura y design
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-2">implantación</div>
          <div className="col-span-4 tec-feature">aws S3 y cloudfront</div>
        </div>

        <div className="h-10" />
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <Carousel images={data.portfolioImages.edges} alt="daucodesigns" />
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer lang="es" />
    </>
  )
}

export default daucodesignsEN

export const query = graphql`
  {
    portfolioImages: allFile(
      filter: { relativePath: { regex: "/webs/daucodesigns/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1280)
          }
        }
      }
    }
  }
`
